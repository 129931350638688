<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
    <a class="navbar-brand" href="\">Astitel</a>
    <!--
    <div class="text-center">
        <img src="assets/img/astitel.svg" class="rounded" style=" height: 80px; width: 270px;">
    </div>
    -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

    <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav mr-auto">
            <li class="nav-item active" *ngIf="user$ | async as user">
                <a class="nav-link" routerLink="/">Tickets Pendientes</a>
            </li>
            <!--
            <li class="nav-item active" *ngIf="user$ | async as user">
                <a class="nav-link" routerLink="/upload-image">Upload</a>
            </li>
            -->
            <li class="nav-item active" *ngIf="user$ | async as user">
                <a class="nav-link" routerLink="/technical-support-request">Nuevo</a>
            </li>
            <li class="nav-item active" *ngIf="user$ | async as user">
                <a class="nav-link" routerLink="/technical-support-request-list">Tickets Cerrados</a>
            </li>
        </ul>
        <ul class="navbar-nav mx-auto" *ngIf="user$ | async as user; else login">
            <li class="nav-item" *ngIf="user">
                <a href="#" class="nav-link" (click)="onLogout()">Logout</a>
            </li>
            <li class="nav-item">
                <a class="nav-link"> {{ user?.email }}</a>
                <a class="nav-link"> {{ user?.displayName }}</a>
            </li>
        </ul>
        <ng-template #login>
            <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/login">Login</a>
                </li>
                <!--Por el momento no tendremos la opcion de-->
                <!--
                <li class="nav-item">
                    <a class="nav-link" routerLink="/register">Register</a>
                </li>
                -->
            </ul>
        </ng-template>
    </div>
</nav>