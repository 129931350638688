<div class="card mt-5" *ngIf="user$ | async as user; else loading">
  <div class="card-body">
    <h3 class="card-title text-center">Thank you for Registering!</h3>
    <div class="form-group mt-2">
      <p class="text-center">
        We have sent a confirmation email to <strong>{{ user?.email }}</strong>
      </p>
      <p class="text-center">
        Please check your email and click on the link to verify your email
        address.
      </p>
    </div>
    <div class="form-group text-center mt-5">
      <button class="btn btn-primary" (click)="onSendEmail()">
        Resend verification email
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  Loading....
</ng-template>
